import FeatureGateClient from '@atlaskit/feature-gate-js-client/client';
import {
    FeatureGateEnvironment,
    Identifiers,
    CustomAttributes,
    PerimeterType
} from '@atlaskit/feature-gate-js-client';
import { get } from 'lodash';
import Logger from '../logger';
import { setBooleanFeatureFlagResolver } from '@atlaskit/platform-feature-flags';

interface EnvConfig {
    apiKey: string;
    environment: FeatureGateEnvironment;
    perimeter: PerimeterType;
    targetApp: string;
}

const TARGET_APP = 'sac-infrastructure_web';
const DEFAULT_OPTIONS = {
    perimeter: PerimeterType.COMMERCIAL,
    targetApp: TARGET_APP
};

export let statsigInitialized = false;
let statsigClient: FeatureGateClient;

const getEnvironmentConfig = (): EnvConfig => {
    const environment: 'prod' | 'staging' | 'dev' | string =
        get(window, 'MICROS_ENVTYPE') || 'dev';
    const STATSIG_CLIENT_API_KEY = get(window, 'STATSIG_CLIENT_API_KEY', '');

    switch (environment) {
        case 'prod':
            return {
                ...DEFAULT_OPTIONS,
                apiKey: STATSIG_CLIENT_API_KEY,
                environment: FeatureGateEnvironment.Production
            };
        case 'staging':
            return {
                ...DEFAULT_OPTIONS,
                apiKey: STATSIG_CLIENT_API_KEY,
                environment: FeatureGateEnvironment.Staging
            };
        case 'dev':
        default:
            return {
                ...DEFAULT_OPTIONS,
                apiKey: STATSIG_CLIENT_API_KEY,
                environment: FeatureGateEnvironment.Development
            };
    }
};

export const getAnalyticsAnonymousId = () => {
    return window?.localStorage
        ?.getItem('STATSIG_STABLE_ID')
        ?.replace(/"/g, '') as string;
};

const configurePlatformFeatureFlags = () => {
    setBooleanFeatureFlagResolver((flagKey: string) => {
        return statsigClient.checkGate(flagKey);
    });
};

export const initialiseStatsigClient = async (
    identifiers?: Identifiers,
    customAttributes?: CustomAttributes
): Promise<boolean> => {
    if (
        statsigClient?.initializeCalled() &&
        statsigClient?.initializeCompleted()
    ) {
        return true;
    }

    if (!identifiers) {
        identifiers = {
            analyticsAnonymousId: getAnalyticsAnonymousId()
        };
    }

    if (!customAttributes) {
        customAttributes = {
            analyticsAnonymousId: getAnalyticsAnonymousId()
        };
    }

    const envConfig = getEnvironmentConfig();

    if (!envConfig.apiKey) {
        statsigInitialized = false;
        Logger.error(
            '[sac-infra] STATSIG client Initialization Error , Missing Client Key'
        );
        return false;
    }

    try {
        statsigClient = new FeatureGateClient();
        await statsigClient.initialize(
            envConfig,
            identifiers,
            customAttributes
        );
        statsigInitialized = true;
        Logger.info('[sac-infra] STATSIG client Initialized');
        configurePlatformFeatureFlags();
        Logger.info('[sac-infra] Platform Feature Flags Configured');
        return true;
    } catch (error) {
        statsigInitialized = false;
        Logger.error(
            { error },
            '[sac-infra] STATSIG client Initialization ERROR'
        );
        return false;
    }
};

export const getFeatureGate = async (
    gateKey: string,
    gateDefaultValue: boolean,
    identifiers?: Identifiers,
    customAttributes?: CustomAttributes
    // eslint-disable-next-line max-params
) => {
    try {
        if (statsigInitialized) {
            if (identifiers) {
                await statsigClient.updateUser(
                    getEnvironmentConfig(),
                    identifiers,
                    customAttributes
                );
            }

            return statsigClient.checkGate(gateKey);
        } else {
            await initialiseStatsigClient(identifiers, customAttributes);
            if (statsigInitialized) {
                return statsigClient.checkGate(gateKey);
            } else {
                return gateDefaultValue;
            }
        }
    } catch (error) {
        Logger.error({ error }, '[sac-infra] STATSIG client ERROR');
        return gateDefaultValue;
    }
};

export const getExperiment = async (
    experimentName: string,
    identifiers: Identifiers = {
        analyticsAnonymousId: getAnalyticsAnonymousId()
    },
    customAttributes?: CustomAttributes
) => {
    if (statsigInitialized) {
        await statsigClient.updateUser(
            getEnvironmentConfig(),
            identifiers,
            customAttributes
        );
        return statsigClient.getExperiment(experimentName);
    } else {
        await initialiseStatsigClient(identifiers, customAttributes);
        return statsigClient.getExperiment(experimentName);
    }
};
